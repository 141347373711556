import "./projects.scss"


export default function Projects() {
  return (
     
      <div class="flex-container" className="projects" id="projects">
      <h2>Projects</h2>
      <h6> Here's Some of My Recent Works</h6>
            
              
            <div className="gridCardContainer">
                  
                        <div className="card">
                        <div className="cardBody">
                              <img className="cardImg" src="assets/addisAbaba.jpg" alt="Addis Ababa Website"></img>
                              <h3 className="cardTitle">Addis Ababa Website</h3>
                         
                        </div> 
                        </div>                     
                
                 
                        <div className="card">
                        <div className="cardBody">
                              <img className="cardImg" src="assets/coffee.jpg" alt="Origin of Coffee Website"></img>
                              <h3 className="cardTitle">Origin of Coffee Website</h3>
                           
                        </div>
                        </div>
                  
                  
                        <div className="card">
                        <div className="cardBody">
                              <img className="cardImg" src="assets/biltmore.jpg" alt="Biltmore Companion App"></img>
                              <h3 className="cardTitle">Biltmore Companion App</h3>
                             
                        </div>
                        </div>  
                   
                        
                  <div className="card">
                  <div className="cardBody">
                        <img className="cardImg" src="assets/stockscounty.jpg"alt="Stokes County Website"></img>
                        <h3 className="cardTitle">Stokes County Website</h3>
                       
                  </div>
                  </div>
                              
                      
          
                  <div className="card">
                  <div className="cardBody">
                        <img className="cardImg" src="assets/pleasureIsland.jpg" alt="Pleasure Island"></img>
                        <h3 className="cardTitle">Pleasure Island Website</h3>
                        
                  </div>
                  </div> 
           
                       
                  <div className="card">
                  <div className="cardBody">
                        <img className="cardImg" src="assets/portfolio.jpg"alt="Portfolio Project"></img>
                        <h3 className="cardTitle">Portfolio Project</h3>
                       
                  </div>
                  </div> 


            </div>  
            </div>                   
            
    
  )
}
