import "./resume.scss"
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';


export default function Resume() {
  return (
    <div class="flex-container" className ="resume" id="resume">        
         <h2> My Resume</h2> 

            <div className="resumewrapper">           
                                                            
                  <div className="resumeImgContainer">
                        <img className="resumeImg" src="assets/ZenashKassaResume.jpg" alt="Resume"></img>
                  </div>
                  
                  <a href ="assets/ZenashKassaResume.pdf" download="ZenashKassaResume.pdf" className="resumePdf">DOWNLOAD RESUME
                        <PictureAsPdfOutlinedIcon className="icon"/></a>
                      
                       
               
              </div>
              </div>
                       
        
  
    
  )
}
