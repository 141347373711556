import "./topbar.scss"
import {FaGithub} from 'react-icons/fa';
import {FaLinkedinIn} from 'react-icons/fa';
import {FaBlogger} from 'react-icons/fa';


export default function Topbar({menuOpen, setMenuOpen}) {

  return (
    <div className= {"topbar " + (menuOpen && "active")}>
      
      <div className="wrapper">
          <div className="left">            
          <a href="#about" className="logo">Zenash Kassa</a>
          <div className="itemContainer">
          <span><a href="https://www.linkedin.com/in/zenash-k-a5ba24b5/">  <FaLinkedinIn size="1.5em" color="limegreen"/></a></span>
          </div>
         
          <div className="itemContainer">
          <span><a href="https://github.com/AfarDanakil/portfolio">  <FaGithub size="1.5em" color="limegreen"/></a></span>
          </div>
          <div className="itemContainer">
          <span><a href="https://webdevelperdotblog.wordpress.com">  <FaBlogger size="1.5em" color="limegreen"/></a></span>
          </div>
         
          </div>          
                        
        <div className="right">

            <div className="hamburger" onClick={()=>setMenuOpen(!menuOpen)}>
              <span className="line1"></span>
              <span className="line2"></span>
              <span className="line3"></span>
            </div> 
        </div>
    </div>
  </div>
  );
}
