import "./intro.scss"

export default function Intro() {
  return (
   
    <div class="flex-container" className="intro" id="intro"> 
     <h2> About Me</h2>   
 
           <div className='imgContainer'>
            <img className="introImg" src="assets/myPicture-min.jpg" alt="Intro"></img>
            </div>                         
           
            <div className="introPar">
            <p>My Name is Zenash Kassa. I am Full-stock web developer with an eye for design.
            I have a passion for learning new technologies. I am looking for new avenues to share my skills and grow in the process.
            Take a look through my portfolio and see what skills I have to offer and some of the work I have 
            created so far. Feel welcome to contact me if you have questions or interests.</p>
                 
             </div>
      </div>
             
            
              
      )
}
