import "./blog.scss"

export default function Blog() {
  return (
    <div className="blog" id="blog">
      <h2>My blog</h2>
      
      <div className="blogWrapper">
           
          <p> On my blog I share tech news and technology resources
          for developers, designers, and digital marketers</p>
      
        <a href="https://webdevelperdotblog.wordpress.com/"> <button className="blogBtn">Read My Blog</button>
</a>
</div>
</div>

      
      

  )
}
